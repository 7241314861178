import React from 'react';
import { ChakraProvider, Box, Grid, Image, Heading, Text, useColorModeValue, useMediaQuery } from '@chakra-ui/react';
import TopBar from './components/Footer';
import NavigationHeader from './components/NavigationHeader';
import FooterBar from './components/GetAQuote';
import theme from "./theme"
import TopHeaderBar from './components/Top';
import Garrett from "./images/team/Garrett.png"
import Grace from "./images/team/Grace.png"
import Joe from "./images/team/Joe.png"
import Sale from "./images/team/Sale.png"
import Kyle from "./images/team/Kyle.png"
import Jacky from "./images/team/Jacky.png"
import Banner from "./pages/Personal-Insurance/Banner"
import PartnersPic from "./images/misc/Partners.jpeg"
import Gina from "./images/team/Gina.jpeg"



function OurTeam() {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const teamMembers = [
    {
      picture: Joe,
      name: 'Joe Center',
      title: 'Director of Insurance',
      description: 'Joe is a local graduate of UAlbany where he studied communications and business, as well as playing football for the Great Danes. Joe is an avid motorcycle fan. Joe is a family man and has been married for over 28 years and is a father to two great children who are following their passions in life and that makes him happy. He currently resides in Altamont, New York.'
    },
    {
      picture: Sale,
      name: 'Sale Khalaf',
      title: 'New Business Agent',
      description: 'Sale "Sally" decided to pursue her Personal Lines Insurance license after the birth of her third son. She loves customer service and makes sure to go the extra mile for her customers.'
    },
    {
      picture: Garrett,
      name: 'Garrett Langille',
      title: 'Account Manager',
      description: 'Garrett has been selling and servicing insurance products for the last 7 years. He was raised in the Capital Region and enjoys playing sports and watching basketball and football games. He is an avid Dallas Cowboys and Los Angeles Lakers fan. His favorite thing is to help people resolve complicated issues.'
    },
    {
      picture: Grace,
      name: 'Grace DePietro',
      title: 'Assistant',
      description: 'Grace is a recent graduate of SUNY Oswego where she studied Communication and Social Interaction. She is a huge New York Yankee fan and enjoys traveling to different sporting events. She also has a love for dogs especially her best friend, 2-year-old Koda. She now resides in Amsterdam, NY after living in the Utica area her whole life.'
    },
    {
      picture: Kyle,
      name: 'Kyle Leonard',
      title: 'Senior Account Manager',
      description: 'Kyle studied chemistry at SUNY Plattsburgh before starting his insurance career in 2014, working with businesses to enhance their employee benefits. After a few years, he expanded to helping customers with their car, home, and business insurance. In his free time, he enjoys tracking the stock market, watching TV shows, gardening, and spending time with his Mini Dachshund Oppie.'
    },
    {
      picture: Jacky,
      name: 'Jacky Torrisi',
      title: 'Agent',
      description: 'Jacky is a graduate of RPI, where she majored in Electronic Media, Art and Communications. She is an avid nature-lover and enjoys outdoor activities such as hiking, camping and paddleboarding. She is passionate about making art and playing music in her spare time. Jacky appreciates meeting new people and takes pride in helping others.'
    },
    {
      picture: Gina,
      name: 'Gina Litz',
      title: 'Sales Manager',
      description: 'Gina has over seven years of expertise in personal and commercial insurance.  She is driven by her passion for helping others protect what matters most.  Outside of work, she cherishes quality time with family and friends.  Whether camping, roller skating with her daughter or cheering on a football game with her son, she thrives on building connections and embracing the joy of community.  She also enjoys fishing, hiking and the challenges of putting together puzzles in her free time.'
    }
  ];
  const [isSmallScreen] = useMediaQuery("(max-width: 600px)");
  

  return (
    <ChakraProvider theme={theme}>
      <Box 
        bg={"gray.100"}
        mx="auto"
        mb={16} 
        overflowX={"hidden"}
      >
        <TopHeaderBar />
        <NavigationHeader />
        <Banner header={"Meet our Team"} img={PartnersPic}/>
        <Grid 
          templateColumns={isSmallScreen ? "repeat(1, 1fr)" : "repeat(3, 1fr)"} 
          gap={6} 
          padding={6} 
          justifyItems="center"
        >
          {teamMembers.map((member, index) => (
            <Box 
              key={index} 
              w={isSmallScreen ? "100%" : "sm"} 
              borderWidth="1px" 
              borderRadius="lg" 
              overflow="hidden"
              boxShadow="2xl"  
              bg={bgColor}
              transition="0.3s"
              _hover={{ transform: "scale(1.02)", boxShadow: "xl" }}
            >
              <Image borderRadius="md" src={member.picture} alt={member.name} />

              <Box p="6">
                <Box d="flex" alignItems="baseline">
                  <Heading fontFamily={"Avenir"} size="lg" fontWeight="semibold" lineHeight="tight" isTruncated color="purple.500">
                    {member.name}
                  </Heading>
                </Box>

                <Box mt={2} color="gray.600">
                  <Text fontSize="xl" fontWeight="bold">
                    {member.title}
                  </Text>
                  <Text mt={2}>
                    {member.description}
                  </Text>
                </Box>
              </Box>
            </Box>
          ))}
        </Grid>
        <FooterBar/>
        <TopBar/>
      </Box>
    </ChakraProvider>
  );

}

export default OurTeam;